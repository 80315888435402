(function () {
    'use strict';
    var mnOximetryBody = {
        controller: mnOximetryBodyCtrl,
        controllerAs: "vm",
        bindings: {
            patient: "<mnPatient",
            readonly: "<mnReadOnly",
            exam: "=mnExam",
            bluetoothDevice: "=mnBluetoothDevice",
            bluetoothServer: "=mnBluetoothServer",
            defaultParams: "=mnDefaultParams",
            oxiServices: "=oxiServices"
        },
        template: require('oximetry/views/oximetry-body.tpl.html')
    };

    mnOximetryBodyCtrl.$inject = ["$translate", "oximetryService", "system"];

    function mnOximetryBodyCtrl($translate, oximetryService, system) {
        var vm = this;

        var oxiDevices = require("../json/oxi-services.json");

        vm.$onDestroy = onDestroy;
        vm.$onInit = init;
        vm.getMeasurement = getMeasurement;
        vm.getMotifs = getMotifs;

        vm.max_hr = () => {
            return _.max(_.map(vm.exam.measurements, 'hr')) || 0;
        };
        vm.min_hr = () => {
            return _.min(_.map(vm.exam.measurements, 'hr')) || 0
        };
        vm.avg_hr = () => {
            return _.chain(vm.exam.measurements).map('hr').sum().divide(vm.exam.measurements.length).round(0).value() || 0;
        };
        vm.min_spo = () => {
            return _.min(_.map(vm.exam.measurements, 'spo')) || 0
        };
        vm.max_spo = () => {
            return _.max(_.map(vm.exam.measurements, 'spo')) || 0
        };
        vm.avg_spo = () => {
            return _.chain(vm.exam.measurements).map('spo').sum().divide(vm.exam.measurements.length).round(0).value() || 0;
        };

        function onDestroy() {
            if (vm.measurementCaracteristic) vm.measurementCaracteristic.removeEventListener('characteristicvaluechanged', console.log('Notification disabled'));
        }

        function init() {
            vm.writeCharacteristic=null;
            vm.oxiServices = oxiDevices[_.get(vm.defaultParams, 'device_model')];
            vm.examTypes = [
                {type: 'six-min', icon: 'mdi-walk'},
                {type: 're-training-effort', icon: 'mdi-run-fast'},
                {type: 'monitoring', icon: 'mdi-clock-fast'},
                {type: 'nocturne', icon: 'mdi-timetable'}
            ];
        }

        function getMeasurement(type) {
            if (_.get(vm.bluetoothServer, 'name') == "O2Ring 7599") {
                enableO2RingMeasurements(type);
            } else {
                vm.bluetoothDevice.getPrimaryService(vm.oxiServices['noninOxiService']['code'])
                    .then(service => service.getCharacteristic(vm.oxiServices['noninOxiService']['oximetry_measurement']))
                    .then(c => {
                        vm.measurementCaracteristic = c;
                        vm.measurementCaracteristic.addEventListener('characteristicvaluechanged',
                            type === 'before' ? measureBefore : measureAfter);
                        return vm.measurementCaracteristic.startNotifications();
                    })
                    .catch(err => console.log('error noninOxiService:', err));
            }
        }

        function measureBefore(event) {
            handleNotifications(event, vm.exam.before_exam);
        }

        function measureAfter(event) {
            handleNotifications(event, vm.exam.after_exam);
        }

        function handleNotifications(event, obj) {
            var a = new Uint8Array(event.target.value.buffer);
            console.log(a)
            obj['spo'] = a[7];
            obj['hr'] =_.get(vm.bluetoothServer,'name')=="O2Ring 7599"? oximetryService.ByteArrayToInt32([a[8], a[9]]) : a[9];
            try {
                vm.measurementCaracteristic.removeEventListener('characteristicvaluechanged', measureBefore);
                vm.measurementCaracteristic.removeEventListener('characteristicvaluechanged', measureAfter);
                vm.measurementCaracteristic.stopNotifications();
            } catch (error) {
                console.log('Argh! ' + error);
            }
        }

        function getMotifs() {
            return _.find(vm.defaultParams['exam_motifs'], {title: vm.exam.type}).motifs;
        }


        // ***************** o2ring measurements **************
        function enableO2RingMeasurements(type, resume = false) {
            vm.bluetoothDevice.getPrimaryService(_.get(vm.oxiServices, 'OxiService.code'))
                .then(service => {
                    return service.getCharacteristic(_.get(vm.oxiServices, 'OxiService.notifyCharacteristic'))
                })
                .then(characteristic => characteristic.startNotifications())
                .then(myCharacteristic => {
                    vm.measurementCaracteristic = myCharacteristic;
                    vm.measurementCaracteristic.addEventListener('characteristicvaluechanged',
                        type === 'before' ? measureBefore : measureAfter);
                })
                .then(c => callO2RingMeasurementsCharacteristic())
                .catch(err => console.log('error:', err));
        }

        function callO2RingMeasurementsCharacteristic() {
            return oximetryService.callO2RingCharacteristic(vm.writeCharacteristic, vm.bluetoothServer,
                vm.bluetoothDevice, vm.oxiServices, Uint8Array.of(0xAA, 0x17, 0xE8, 0x00, 0x00, 0x00, 0x00, 0x1B));
        }

    }

    module.exports = mnOximetryBody;

})();