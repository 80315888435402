/**
 * Created by amine on 09/04/2017.
 */
(function () {

    'use strict';

    module.exports = PrintingCtrl;

    PrintingCtrl.$inject = ["authService", "printService", "$state", "$stateParams", "$q", "$mdDialog"];

    function PrintingCtrl(authService, printService, $state, $stateParams, $q, $mdDialog) {
        let vm = this;
        let uploadDialog = require('parameters/dialogs/printing-image-dialog');
        //let dialog = require('stand-alone/file-manager/dialogs/visualize-image-dialog');
        const dialog = require('stand-alone/file-manager/dialogs/visualize-file-dialog');

        vm.$onInit = init;
        vm.defaults = defaults;
        vm.images = images;
        vm.edit = edit;
        vm.submit = submit;
        vm.remove = remove;
        vm.duplicate = duplicate;
        vm.uploadImage = uploadImage;
        vm.removeImage = removeImage;
        vm.visualizeImage = visualizeImage;
        vm.icon = icon;

        function init() {
            let promises = [printService.getGroups()];

            vm.is_root = authService.isRootProfile();
            vm.form = "parameters/views/printing-template-form.html";
            vm.promise = null;

            if (!_.isNil($stateParams.uid)) promises.push(printService.getTemplate($stateParams.uid));

            $q.all(promises)
                .then(success);

            function success(data) {
                toState($stateParams.uid, $stateParams.s)
                refreshGroups(data[0]);
                if (!_.isNil(data[1])) vm.template = data[1];

            }
        }

        function defaults() {
            toState(null, 'defaults')
        }

        function images() {
            toState(null, 'images')
        }

        function edit(template) {
            printService.getTemplate(template.uid)
                .then(gotTemplate);
        }

        function submit() {
            if(!vm.is_root) return false;

            vm.promise = printService.saveTemplate(vm.template);
        }

        function refreshGroups(groups) {
            vm.groups = groups;
            vm.imagesGroup = _.find(vm.groups, {name: "images"})
        }

        function remove(template) {
            if(!vm.is_root) return false;

            vm.promise = printService.removeTemplate(template)
                .then(success);

            function success() {
                toState()
                vm.template = null;
                printService.getGroups().then(refreshGroups)
            }
        }

        function duplicate(template) {
            if(!vm.is_root) return false;

            printService.duplicateTemplate(template)
                .then(success)

            function success(data) {
                gotTemplate(data);
                printService.getGroups().then(refreshGroups)
            }
        }

        function gotTemplate(data) {
            toState(data.uid, "edit");
            vm.template = data;
        }

        function icon(template) {
            switch (template.type) {
                case "HTML":
                    return "html5";
                case "CSS":
                    return "css3";
            }
        }

        function toState(uid, s) {
            uid = _.isNil(uid) ? null : uid;
            s = _.isNil(s) ? "default" : s;
            $state.go("app.parameters.printing", {uid: uid, s: s}, {inherit: true});
            vm.images_state = false;

            switch (s) {
                case "edit":
                    vm.form = "parameters/views/printing-template-form.html";
                    break;
                case "images":
                    vm.images_state = true;
                    vm.form = "parameters/views/printing-images-form.html";
                    break;
                default:
                    vm.form = "parameters/views/printing-defaults-form.html";
            }
        }

        function uploadImage($ev) {
            if(!vm.is_root) return false;

            $mdDialog.show(_.assign(uploadDialog, {
                targetEvent: $ev
            })).then(getFiles);

            function getFiles(files) {
                printService.saveImages(files)
                    .then(success)
            }

            function success(imageGroup) {
                vm.imagesGroup = imageGroup;
            }
        }

        function removeImage(image) {
            if(!vm.is_root) return false;

            vm.promise = printService.removeTemplate(image)
                .then(success);

            function success() {
                printService.getGroups().then(refreshGroups)
            }
        }

        function visualizeImage(image, $ev) {
            const file = _.assign(image, {
                isBase64: true,
                mime: "image/*"
            });

            $mdDialog.show(_.assign(dialog, {
                targetEvent: $ev,
                locals: {
                    files: [file],
                    fileIndex: 0,
                    onlyOne: true,
                    allowEdit: false,
                    allowDownload: false
                }
            }));
        }
    }

})();