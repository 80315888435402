/**
 * Created by Betalos on 16/07/2016.
 */
(function () {
    'use strict';

    module.exports = {
        controller: mnMedicalFileCtrl,
        controllerAs: 'vm',
        bindings: {
            patient: '<?',
            maxDate: "<?",
            mnMaxDate: "<?",
            ifEmpty: "<?hideIfEmpty",
            readonly: "<?",
            readonlyGynecologyInfo: "<?"
        },
        restrict: 'E',
        template: require("patient/views/medical-file.tpl.html"),
    };

    mnMedicalFileCtrl.$inject = [
        "patientService", "configService", "pregnancyService", "$scope", "$element", "$q", "system"
    ];

    function mnMedicalFileCtrl(patientService, configService, pregnancyService, $scope, $element, $q, system) {
        let vm = this;

        let first = true;
        let dateFormat = system['date_format'].js;

        vm.$onInit = init;
        vm.handleAutoSave = _.mnDelay(startAutoSave, 750);

        vm.next = next;
        vm.choose = choose;
        vm.previous = previous;

        function init() {
            let unwatch = _.noop;

            vm.ifEmpty = vm.ifEmpty || false;
            vm.isGynecoInfoRO = vm.readonlyGynecologyInfo || false;


            $element
                .addClass("flex-noshrink")
                .addClass("layout-column");

            if ($element.is('[mn-max-date]')) $scope.$watch('vm.mnMaxDate', handleMnDate);
            else if ($element.is('[max-date]')) unwatch = $scope.$watch('vm.maxDate', handleDate);
            else unwatch = $scope.$watch('vm.patient', handlePatient);

            if (configService.checkActiveModule("has_pregnancy_block")) {
                pregnancyService.medicalFileChanged.subscribe(next => {
                    promises().then(success);
                });
            }

            //watch for changing date
            function handleMnDate(date) {
                if (_.isNil(date) && first) first = false;

                promises().then(success);
            }

            function handlePatient(patient) {
                if (patient) promises().then(success);
            }

            function handleDate(date) {
                if (_.isUndefined(date) && first) return first = false;
                else return promises().then(success);
            }

            function success(data) {
                unwatch();

                vm.versions = data[0];
                vm.model = data[1];
                vm.config = data[2] || {};
                vm.pregnancy = data[3] || null;

                if (vm.ifEmpty && vm.versions.length === 0) $element.hide();

                if (_.isEmpty(vm.model) && vm.versions.length > 0) {
                    vm.currentVersion = vm.versions.length - 1;
                    choose();
                } else vm.currentVersion = _.findIndex(vm.versions, _.pick(vm.model, 'id'));
            }
        }

        function promises() {
            let promises = [
                patientService.getMedicalFileHistory(vm.patient),
                patientService.getActiveMedicalFile(vm.patient, vm.maxDate),
                configService.get("medical_file_config")
            ];

            if (configService.checkActiveModule("has_pregnancy_block"))
                promises.push(pregnancyService.getLast(vm.patient));

            return $q.all(promises);
        }

        function startAutoSave() {
            if (vm.currentVersion < 1 && vm.model['created_at'] !== moment().format(dateFormat)) {
                vm.model = _.chain(vm.model)
                    .omit('id')
                    .assign({patient: {id: vm.patient}})
                    .value();
            }

            patientService.handleMedicalFile(vm.model, vm.patient).then(success);

            function success(data) {
                let file = _.pick(data, ['id', 'created_at']);

                vm.model = _.assign(vm.model, file);

                vm.versions = _.chain(vm.versions).pushOrUpdate(file).orderBy(function (item) {
                    return moment(item['created_at'], dateFormat).valueOf();
                }, 'desc').value();

                vm.currentVersion = _.findIndex(vm.versions, file);

                if (configService.checkActiveModule("has_pregnancy_block"))
                    pregnancyService.medicalFileChanged.next(true);
            }
        }

        function next() {
            vm.currentVersion -= 1;
            choose();
        }

        function choose() {
            let version = _.get(vm.versions, `[${vm.currentVersion}].id`);

            vm.promise = patientService.getMedicalFileVersion(version)
                .then(changeVersion);
        }

        function previous() {
            vm.currentVersion += 1;
            choose();
        }

        function changeVersion(data) {
            vm.model = data;
        }
    }

})();
