(function () {

    'use strict';

    module.exports = ParamsCtrl;

    ParamsCtrl.$inject = ["$translate", "$state", "$transitions", "$scope", "configService", "authService"];

    function ParamsCtrl($translate, $state, $transitions, $scope, configService, authService) {
        let vm = this;
        const parameters = require('../json/parametres.json');

        vm.key = '';

        vm.$onInit = init;

        vm.showItem = showItem;
        vm.filterMenu = filterMenu;
        vm.clearKey = clearKey;

        function init() {
            vm.parameters = parameters;

            let listener = $transitions.onStart({}, toList);

            if ($state.$current['name'] === "app.parameters") $state.go("app.parameters.general", {});

            function toList(transition) {
                let toState = transition.to();

                if (!_.eq(toState.name, "app.parameters.general") && _.eq(toState.name, "app.parameters")) {
                    $state.go("app.parameters.general", {});
                }
            }

            $scope.$on("$destroy", onDestroy);

            function onDestroy() {
                listener();
            }
        }


        function showItem(item) {
            const showList = _.get(item, "show", []);
            const hideList = _.get(item, "hide", []);
            const show = _.isEmpty(showList) ? true : _.reduce(showList, handleConditions, true);
            const hide = _.isEmpty(hideList) ? false : _.reduce(hideList, handleConditions, true);

            return show && !hide;

            function handleConditions(result, item) {
                if (item === 'is_root') {
                    return result && authService.isRootProfile();
                } else if (item === "has_special_power") {
                    return result && authService.hasSpecialPower();
                } else if (_.has(configService.activeModules, item)) {
                    return result && _.get(configService.activeModules, item);
                } else {
                    return result;
                }
            }
        }

        function filterMenu() {
            if (_.size(vm.key) === 0) {
                vm.parameters = parameters;
            } else {
                let key = _.chain(vm.key).toLower().deburr().value();
                let list = [];

                _.forEach(parameters, function (parameter) {
                    let label = _.chain($translate.instant(parameter.label)).toLower().deburr().value();
                    if (_.includes(label, key)) {
                        list.push(parameter);
                    } else {
                        let sublist = [];

                        _(parameter.items).forEach(function (item) {
                            let label = _.chain($translate.instant(parameter.label)).toLower().deburr().value();
                            if (_.includes(label, key)) sublist.push(item);
                        });

                        if (_.size(sublist) > 0) list.push({label: parameter.label, items: sublist});
                    }
                });

                vm.parameters = list;
            }
        }

        function clearKey() {
            vm.key = '';
            vm.parameters = parameters;
        }
    }

})();


