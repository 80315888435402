/**
 * Created by amine on 19/10/2020.
 */
(function () {

    'use strict';

    const PROCEDURE_GROUP_DIALOG = require('medical-care/dialogs/procedure-group-dialog');
    const PROCEDURE_DIALOG = require('medical-care/dialogs/procedure-dialog');
    const EMPTY_PROTOCOL = {
        cures: []
    };

    class MedicalCareSetupCtrl {
        constructor(medicalCareService, configService, $q, $mdDialog, $scope, dragulaService, $state, $transition$, $translate) {
            this.q = $q;
            this.medicalCareService = medicalCareService;
            this.configService = configService;
            this.dialog = $mdDialog;
            this.state = $state;
            this.transition = $transition$;
            this.translate = $translate;

            this.procedures = [];
            this.procedureGroups = [];
            this.protocols = [];
            this.config = null;

            this.selectedTabIndex = 0;
            this.selectedTab = null;
            this.proceduresList = null;
            this.procedureGroupsList = null;
            this.protocolsList = null;
            this.showForm = false;

            dragulaService.options($scope, "group-bag", {
                revertOnSpill: false,
                moves: function (el, container, handle) {
                    return $(handle).is('.md-button.drag-handle') || $(handle).is('.drag-handle > .mdi-drag');
                }
            });

            let groupEndSortDelay = _.mnDelay(() => this.endSort('groups', this.procedureGroups), 500);
            let groupDrake = dragulaService.find($scope, "group-bag").drake;

            groupDrake.on("dragend", () => groupEndSortDelay());

            dragulaService.options($scope, "protocol-bag", {
                revertOnSpill: false,
                moves: function (el, container, handle) {
                    return $(handle).is('.md-button.drag-handle') || $(handle).is('.drag-handle > .mdi-drag');
                }
            });

            let protocolEndSortDelay = _.mnDelay(() => this.endSort('protocols', this.protocols), 500);
            let protocolDrake = dragulaService.find($scope, "protocol-bag").drake;

            protocolDrake.on("dragend", () => protocolEndSortDelay());
        }

        static get $inject() {
            return ["medicalCareService", "configService", "$q", "$mdDialog", "$scope", "dragulaService", "$state", "$transition$", "$translate"];
        }

        endSort(key, list) {
            this.config[`${key}_list_order`] = _.map(list, 'id');
            this.configService.set({"medical_care_config": this.config});
        }

        $onInit() {
            this.q.all([
                this.medicalCareService.getProcedureGroups(),
                this.medicalCareService.getProcedures(),
                this.medicalCareService.getProtocols(),
                this.configService.getByHttp('medical_care_config')
            ]).then(data => {
                this.config = data[3] || {};

                this.procedureGroups = this.orderList(data[0], 'groups');
                this.procedures = data[1];
                this.protocols = this.orderList(data[2], 'protocols');

                const protocolId = _.get(this.transition.params('to'), 'protocol');

                if (protocolId) {
                    this.handleProtocol(_.chain(this.protocols).find(['id', protocolId]).cloneDeep().value(), false);
                } else {
                    this.protocol = null;
                }
            });
        }

        selectTab(tab) {
            this.selectedTab = tab;
        }

        formClosed(quit) {
            this.state.go("app.parameters.medical-care-setup", {
                'protocol': null,
                'copy': null,
            });

            this.selectedTabIndex = 1;
            this.protocol = null;
            this.showForm = false;

            this.medicalCareService.getProtocols()
                .then(data => this.protocols = this.orderList(data, 'protocols'))
        }

        handleProtocol(item, newIfEmpty = true) {
            if (item) {
                this.state.go("app.parameters.medical-care-setup", {
                    'protocol': item.id,
                    'copy': false,
                });

                this.showForm = true;
                this.protocol = item;
                this.selectedTabIndex = 2;
            } else if (newIfEmpty) {
                this.state.go("app.parameters.medical-care-setup", {
                    'protocol': null,
                    'copy': false,
                });

                this.showForm = true;
                this.protocol = _.cloneDeep(EMPTY_PROTOCOL);
                this.selectedTabIndex = 2;
            }
        }

        handleProcedure(item, event) {
            this.dialog
                .show(_.assign({}, PROCEDURE_DIALOG, {
                    targetEvent: event,
                    locals: {
                        model: _.cloneDeep(item)
                    }
                }))
                .then(data => _.pushOrUpdate(this.procedures, data), _.noop);
        }

        removeProcedure(item, event) {
            const confirm = this.dialog.confirm()
                .targetEvent(event)
                .ariaLabel('removeProcedure')
                .ok(this.translate.instant('confirm_ok'))
                .cancel(this.translate.instant('confirm_cancel'))
                .title(this.translate.instant('medical_care.remove_confirm'));

            this.dialog.show(confirm)
                .then(() => {
                    this.medicalCareService.deleteProcedure(item, this.type);
                }, _.noop);
        }


        handleProcedureGroup(item, event) {
            this.dialog
                .show(_.assign({}, PROCEDURE_GROUP_DIALOG, {
                    targetEvent: event,
                    locals: {
                        model: _.cloneDeep(item)
                    }
                }))
                .then(data => _.pushOrUpdate(this.procedureGroups, data), _.noop);
        }

        removeProcedureGroup(item, event) {
            const confirm = this.dialog.confirm()
                .targetEvent(event)
                .ariaLabel('removeProcedureGroup')
                .ok(this.translate.instant('confirm_ok'))
                .cancel(this.translate.instant('confirm_cancel'))
                .title(this.translate.instant('medical_care.remove_confirm'));

            this.dialog.show(confirm)
                .then(() => {
                    this.medicalCareService.deleteProcedureGroup(item, this.type);
                }, _.noop);
        }

        orderList(list, key) {
            const order = this.config[`${key}_list_order`];

            return this.medicalCareService.orderList(list, order)
        }

    }

    module.exports = MedicalCareSetupCtrl;

})();
