/**
 * Created by BETALOS on 09/01/2017.
 */
(function () {

    'use strict';


    const COLOR_PICKER_PARAMS = require('../json/color-picker-config.json');

    class ConsultationSetupFormCtrl {
        constructor(
            $state, blockService, $transition$, visitService, $q, consultationService, $mdToast,
            $translate
        ) {
            this.q = $q;
            this.state = $state;
            this.toast = $mdToast;
            this.transition$ = $transition$;

            this.blockService = blockService;
            this.visitService = visitService;
            this.consultationService = consultationService;


            this.options = _.assign({}, COLOR_PICKER_PARAMS, {label: $translate['instant']('color')});
            this.simpleToast = $mdToast.simple()
                .hideDelay(2000).position("bottom left")
                .textContent($translate.instant('exam_setup_edit_success'));
        }

        static get $inject() {
            return [
                "$state", "blockService", "$transition$", "visitService", "$q", "consultationService", "$mdToast",
                "$translate"
            ];
        }

        $onInit() {
            let currentTemplate = _.get(this.transition$.params('to'), 'template');

            let promises = [
                this.blockService.getBlockList(),
                this.visitService.getProcedures()
            ];

            if (currentTemplate) promises.push(this.consultationService.getConfigItem(currentTemplate))
            else promises.push({procedures: [], html_blocks: []});

            this.promise = this.q.all(promises).then(data => {
                _.remove(data[0], {key: "5874bbe314f40d223498e5f9"});

                this.blocks = data[0];
                this.procedures = data[1];
                this.template = data[2];
            });
        }

        goBack() {
            this.state.go('app.parameters.consultation-setup');
        }

        addNewBlock() {
            this.template['html_blocks'].push(null);
        }

        removeBlock(index) {
            this.template['html_blocks'].splice(index, 1);
        }

        fixBlock(block, index) {
            this.template['html_blocks'][index] = block;
        }

        checkDisabled(block) {
            return _.includes(this.template['html_blocks'], block.key);
        }

        addNewProcedure() {
            this.template['procedures'].push(null);
        }

        removeProcedure(index) {
            this.template['procedures'].splice(index, 1);
        }

        fixProcedure(procedure, index) {
            this.template['procedures'][index] = procedure;
        }

        checkProcedure(procedure) {
            return _.findIndex(this.template['procedures'], ['id', procedure.id]) != -1;
        }

        handleSave(quit) {
            return this.consultationService.saveConfig(this.template)
                .then(data => {
                    this.template = data;
                    setTimeout(() => this.toast.show(this.simpleToast), 100);

                    if (quit) return this.goBack();
                    else {
                        this.state.go("app.parameters.consultation-setup-form", {template: data.id}, {location: 'replace'});
                    }
                });
        }

    }

    module.exports = ConsultationSetupFormCtrl;

})();
