(function () {

    'use strict';

    class PatientCustomFieldsCtrl {
        constructor(patientService, genericStatesService) {
            this.patientService = patientService;
            this.genericStatesService = genericStatesService;

            this.model = {};
            this.fields = this.patientService.customFields || [];
            this.ngModelController = this.ngModelController || null;

            this.dateFormat = {
                'month': genericStatesService.buildLocaleProvider("MM/YYYY"),
                'year': genericStatesService.buildLocaleProvider("YYYY")
            }
        }

        static get $inject() {
            return ["patientService", "genericStatesService"];
        }

        $onInit() {
            this.ngModelController.$render = () => this.renderCurrentValue();
        }

        internalValueChanged() {
            this.ngModelController.$setViewValue(this.model);
            this.ngModelController.$commitViewValue();
        }

        renderCurrentValue() {
            if (this.ngModelController.$viewValue !== this.model) {
                this.model = this.ngModelController.$viewValue;
            }
        }
    }

    module.exports = {
        bindings: {},
        controllerAs: "vm",
        controller: PatientCustomFieldsCtrl,
        require: {ngModelController: "ngModel"},
        template: require('../views/patient-custom-fields.tpl.html')
    };

})();
