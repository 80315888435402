/**
 * Created by BETALOS on 11/10/2016.
 */
(function () {

    'use strict';

    const ROOM_DIALOG = require('frontdesk/dialogs/room-dialog');
    const REASON_DIALOG = require('frontdesk/dialogs/reason-dialog');
    const AGENDA_FORM_DIALOG = require('frontdesk/dialogs/agenda-form-dialog');
    const BACKGROUND_EVENT_DIALOG = require('frontdesk/dialogs/background-event-dialog');

    const AGENDA_PARAMS = require('../json/agenda-params.json');
    const COLOR_PICKER_PARAMS = require('../json/color-picker-config.json');
    const {BACKGROUND,  DELETE} = require('stand-alone/calendar/utils/consts');

    module.exports = AgendaConfigCtrl;

    AgendaConfigCtrl.$inject = [
        "frontDeskService", "configService", "physicianService", "$timeout", "$translate", "$q", "$mdToast",
        "$mdDialog", "mnWebSocket", "$transition$"
    ];

    function AgendaConfigCtrl(
        frontDeskService, configService, physicianService, $timeout, $translate, $q, $mdToast, $mdDialog, mnWebSocket,
        $transition$
    ) {
        let vm = this;

        let promises = [
            configService.get(["calendar_config", "doctors_colors", 'frontdesk_patient_list', 'practice_patient_exit', 'appointment_reminder'], false),
            physicianService.list(),

            frontDeskService.getReasons(),
            frontDeskService.getAgendas(),
            frontDeskService.roomSubject.getValue(),
            frontDeskService.getBackgroundEvents(),
            configService.get("agenda_list_config"),
        ];

        vm.$onInit = init;
        vm.change = _.mnDelay(startAutoSave, 400);

        vm.handleReminderConf = _.mnDelay(handleReminderConf, 400);
        vm.handleFrontDeskPatient = _.mnDelay(handleFrontDeskPatient, 400);
        vm.handlePracticePatientExit = _.mnDelay(handlePracticePatientExit, 400);
        vm.handleAgendaConfigList = _.mnDelay(handleAgendaConfigList, 750);

        vm.updateReason = updateReason;
        vm.removeReason = removeReason;

        // agenda color event
        let physicians = [];
        let staffConfigs = [];

        vm.physicianSelected = physicianSelected;
        vm.updateStaffConfig = updateStaffConfig;

        //agenda
        vm.handleAgenda = handleAgenda;
        vm.removeAgenda = removeAgenda;

        //background event
        vm.handleEvent = handleEvent;
        vm.removeBackgroundEvent = removeBackgroundEvent;

        //Room
        vm.handleRoom = handleRoom;
        vm.removeRoom = removeRoom;

        function init() {
            vm.timeZones = moment.tz.names();
            vm.weekDays = AGENDA_PARAMS['week-days'];
            vm.timeSlots = AGENDA_PARAMS['time-slots'];
            vm.calendarView = AGENDA_PARAMS['calendar-view'];
            vm.isEvents = _.get($transition$.params('to'), 'events');

            vm.selectedTab = 'general';

            vm.options = _.assign({}, COLOR_PICKER_PARAMS, {label: $translate['instant']('color')});

            vm.physicianTable = [];
            vm.staffConfig = null;

            vm.promise = $q.all(promises).then(success);
        }

        function success(data) {
            vm.calendarConfig = data[0]['calendar_config'];
            vm.frontDeskPatientList = data[0]['frontdesk_patient_list'];
            vm.practicePatientExitOnlyVisit = data[0]['practice_patient_exit'];

            vm.reminderConf = _.isNil(data[0]['appointment_reminder']) ? {duration: 5} : data[0]['appointment_reminder'];


            staffConfigs = _.isNil(data[0]['doctors_colors']) ? [] : data[0]['doctors_colors'];
            physicians = data[1];
            vm.physicianTable = createPhysicianColorTable(physicians, staffConfigs);

            vm.reasons = data[2] || [];
            vm.agendas = data[3] || [];
            vm.rooms = data[4] || [];
            vm.backgroundEvents = data[5] || [];
            vm.agendaListConfig = data[6] || {};
        }


        function startAutoSave() {
            configService.set({"calendar_config": vm.calendarConfig})
                .then(done);
        }

        function handleReminderConf() {
            configService.set({"appointment_reminder": vm.reminderConf})
                .then(done);
        }

        function handleFrontDeskPatient() {
            configService.set({"frontdesk_patient_list": vm.frontDeskPatientList})
                .then(done);
        }

        function handlePracticePatientExit() {
            configService.set({"practice_patient_exit": vm.practicePatientExitOnlyVisit})
                .then(done);
        }

        function handleAgendaConfigList() {
            configService.set({"agenda_list_config": vm.agendaListConfig}, true)
                .then(success);

            function success() {
                const simpleToast = $mdToast.simple()
                    .textContent($translate['instant']('exam_setup_edit_success'))
                    .position("bottom left")
                    .hideDelay(1500);

                $timeout(function () {
                    $mdToast.show(simpleToast);
                }, 100);

            }
        }

        function done() {
            let simpleToast = $mdToast.simple()
                .textContent($translate['instant']('exam_setup_edit_success'))
                .position("bottom left")
                .hideDelay(1500);

            $timeout(function () {
                $mdToast.show(simpleToast);
            }, 100);
        }

        function updateReason(reason, $event) {
            $mdDialog.show(_.assign({}, REASON_DIALOG, {
                targetEvent: $event,
                locals: {
                    model: _.cloneDeep(reason)
                }
            })).then(updateReasonDone);

            function updateReasonDone(data) {
                vm.reasons = _.pushOrUpdate(vm.reasons, data);

                let simpleToast = $mdToast.simple()
                    .textContent($translate['instant']('exam_setup_edit_success'))
                    .position("bottom left")
                    .hideDelay(1500);

                $timeout(function () {
                    $mdToast.show(simpleToast);
                }, 100);
            }
        }


        function removeReason(reason, ev) {
            let confirm = $mdDialog.confirm()
                .title($translate['instant']('agenda_reason_remove_confirm', {title: reason.description}))
                .ariaLabel('remove plan confirm')
                .targetEvent(ev)
                .ok($translate['instant']('confirm_ok'))
                .cancel($translate['instant']('confirm_cancel'));

            $mdDialog.show(confirm).then(remove);

            function remove() {
                vm.promise = frontDeskService.removeReason(reason)
                    .then(done)
            }

            function done() {
                _.pull(vm.reasons, reason);
            }
        }

        function physicianSelected() {
            let staffConfig = _.find(staffConfigs, {staff_id: vm.physician.id});
            vm.staffConfig = _.isUndefined(staffConfig) ? _.assign(vm.staffConfig, {staff_id: vm.physician.id}) : _.cloneDeep(staffConfig);
        }

        function updateStaffConfig(staffID) {
            if (staffID) _.remove(staffConfigs, {'staff_id': staffID});
            else staffConfigs = _.pushOrUpdate(staffConfigs, vm.staffConfig, 'staff_id');

            configService.set({"doctors_colors": staffConfigs})
                .then(done);

            function done() {
                vm.physicianTable = createPhysicianColorTable(physicians, staffConfigs);
                vm.staffConfig = vm.physician = null;

                let simpleToast = $mdToast.simple()
                    .textContent($translate['instant']('exam_setup_edit_success'))
                    .position("bottom left")
                    .hideDelay(1500);

                $timeout(function () {
                    $mdToast.show(simpleToast);
                }, 100);
            }
        }

        function createPhysicianColorTable(physicians, configs) {
            return _.reduce(configs, function (array, item) {
                let p = _.find(physicians, {id: item['staff_id']});
                item = _.assign(item, _.pick(p, 'full_name'));

                return _.concat(array, item);
            }, []);
        }

        //agenda
        function handleAgenda(agenda, ev) {
            $mdDialog.show(_.assign({}, AGENDA_FORM_DIALOG, {
                targetEvent: ev,
                locals: {
                    model: _.cloneDeep(agenda)
                }
            })).then(success);

            function success(data) {
                vm.agendas = _.pushOrUpdate(vm.agendas, data);
            }
        }

        function removeAgenda(agenda, ev) {
            frontDeskService.removeAgenda(agenda, ev)
                .then(success);

            function success() {
                _.remove(vm.agendas, {id: agenda.id});
            }
        }

        // background event func
        function handleEvent(eventObj, ev) {
            $mdDialog.show(_.assign({}, BACKGROUND_EVENT_DIALOG, {
                targetEvent: ev,
                locals: {
                    event: _.cloneDeep(eventObj)
                }
            })).then(refreshTable);
        }

        function refreshTable(event) {
            frontDeskService.getBackgroundTableEvent(event.id)
                .then(done)

            function done(data) {
                vm.backgroundEvents = _.pushOrUpdate(vm.backgroundEvents, data);

                let simpleToast = $mdToast.simple()
                    .textContent($translate['instant']('block_setup_event_change'))
                    .position("bottom left")
                    .hideDelay(1500);

                $timeout(function () {
                    $mdToast.show(simpleToast);
                }, 100);
            }
        }

        function removeBackgroundEvent(event) {
            frontDeskService.removeBackgroundEvent(event.id)
                .then(done);

            function done() {
                mnWebSocket.pub("frontdesk.Calendar.notify", {cmd: DELETE, type: BACKGROUND, event: event.id}, false);
                _.remove(vm.backgroundEvents, {id: event.id});
            }
        }

        //room
        function handleRoom(room, ev) {
            $mdDialog.show(_.assign({}, ROOM_DIALOG, {
                targetEvent: ev,
                locals: {
                    model: _.cloneDeep(room)
                }
            })).then(data => vm.rooms = _.pushOrUpdate(vm.rooms, data));
        }

        function removeRoom(room, ev) {
            let confirm = $mdDialog.confirm()
                .title($translate['instant']('agenda_room_config_remove_confirm', room))
                .ariaLabel('remove plan confirm')
                .targetEvent(ev)
                .ok($translate['instant']('dental_module_ok'))
                .cancel($translate['instant']('dental_module_cancel'));

            $mdDialog.show(confirm).then(() => {
                frontDeskService.removeRoom(room).then(() => vm.rooms = _.without(vm.rooms, room));
            });
        }
    }

})();
