/**
 * Created by amine on 31/05/2021.
 */
(function () {
    'use strict';
    const PATHOLOGY_DIALOG = require('specifics/dialogs/pathologies-dialog');

    class PathologiesSetupCtrl {
        constructor(pathologyService, $translate, $mdDialog, $mdToast) {
            this.dialog = $mdDialog;
            this.toast = $mdToast;
            this.pathologyService = pathologyService;

            this.promise = null;

            this.paginationLabel = {
                page: $translate.instant('page'),
                rowsPerPage: $translate.instant('rowsPerPage'),
                of: $translate.instant('of')
            };
            this.options = [20, 15, 10, 5];

            this.simpleToast = this.toast.simple()
                .textContent($translate['instant']('exam_setup_edit_success'))
                .position("bottom left")
                .hideDelay(500);

            this.pathologies = [];
            this.data = [];
            this.total = 0;
            this.query = {
                limit: 15,
                page: 1,
                search_all: ""
            };
        }

        static get $inject() {
            return ["pathologyService", "$translate", "$mdDialog", "$mdToast"];
        }

        $onInit() {
            this.onPaginate = (page, limit) => this._onPaginate(page, limit);

            this.getData();
        }

        handlePathology(pathology, event) {
            this.dialog.show(_.assign(PATHOLOGY_DIALOG, {
                targetEvent: event,
                locals: _.has(pathology, "id") ? {model: _.cloneDeep(pathology)} : {}
            })).then(result => {
                this.getData();
                this.query.search_all = result.name;
            }, _.noop);
        }

        removeConfiguration(pathology) {
            this.pathologyService.removePathology(pathology)
                .then(() => this.getData(), _.noop);
        }

        getData() {
            this.promise = this.pathologyService
                .getPathologies()
                .then(data => {
                    this.total = data.length;
                    this.initPathologies = data;
                    this.populateData(this.initPathologies);

                    if (this.total < ((this.query.page - 1) * this.query.limit))
                        this.query.page = 1;
                });
        }

        _onPaginate(page, limit) {
            page -= 1;
            this.data = _.slice(this.pathologies, page * limit, (page + 1) * limit)
        }

        populateData(data) {
            this.pathologies = _.orderBy(data, [path => path.searchable_value.toLowerCase()], ['asc']);
            this.query.page = 1;
            this._onPaginate(this.query.page, this.query.limit);
        }

        filteredData(newData) {
            this.populateData(newData);
        }

        cancel() {
            this.dialog.cancel();
        }
    }

    module.exports = PathologiesSetupCtrl;
})();