/**
 * Created by BETALOS on 14/01/2017.
 */
(function () {

    'use strict';

    module.exports = ExamSetupCtrl;

    const EXAM_SETUP_DIALOG = require('../dialogs/exam-template-dialog');

    ExamSetupCtrl.$inject = ["examService", "configService", "$mdToast", "$translate", "$q", "$timeout", "$mdDialog", "dragulaService", "$scope"];

    function ExamSetupCtrl(examService, configService, $mdToast, $translate, $q, $timeout, $mdDialog, dragulaService, $scope) {
        let vm = this;
        let initModels = [];

        vm.lock = true;
        vm.$onInit = init;

        vm.examForm = examForm;
        vm.handleConfig = handleConfig;
        vm.templateSelected = templateSelected;
        vm.modelsTab = modelsTab;

        vm.editModel = editModel;
        vm.duplicateModel = duplicateModel;
        vm.moveModel = examService.moveExamModel;
        vm.deleteModel = examService.deleteExamModel;

        let bag = "item-bag";
        let handleSave = _.mnDelay(startSave, 750);

        function init() {
            let currentModelSubscription = examService.currentModelSubject.subscribe(currentModelChanged);
            let modelsSubscription = examService.examModelSubject.subscribe(modelsChanged);
            let selectedTemplateSubscription = examService.selectedTemplate.subscribe(filterExamModels);

            vm.examTemplates = [];
            vm.promise = $q.all([examService.getExamTemplates(), examService.getExamModels(), configService.get("exam_config", true)])
                .then(success);

            dragulaService.options($scope, bag, {
                revertOnSpill: false,
                moves: function (el, container, handle) {
                    return $(handle).is('.md-button.drag-handle') || $(handle).is('.drag-handle > .mdi-drag');
                }
            });

            let drake = dragulaService.find($scope, bag).drake;
            drake.on("dragend", _.mnDelay(handleOrder, 400));

            $scope.$on("$destroy", onDestroy);

            function success(data) {
                vm.config = !_.isNil(data[2]) ? data[2] : {
                    disabled: {},
                    favorite: {}
                };

                vm.examTemplates = _.isNil(vm.config.order) ? data[0] : _.orderBy(data[0], function (item) {
                    return _.get(vm.config, `order.${item.id}`);
                });

                initModels = data[1];
                filterExamModels();
            }

            function currentModelChanged(status) {
                vm.currentModel = status;
            }

            function modelsChanged(status) {
                console.log(status);
                return false;
                if (_.has(status, "added")) initModels = _.pushOrUpdate(initModels, status.added);

                if (_.has(status, "deleted")) {
                    let i = _.findIndex(initModels, {id: status.deleted});
                    initModels.splice(i, 1);
                }
                filterExamModels();
            }

            // function filterExamModels(template) {
            //     if (_.isNil(template)) template = examService.selectedTemplate.getValue();
            //
            //     if (template) vm.models = _.chain(initModels)
            //         .filter({template_id: _.isNumber(template) ? template : template.id})
            //         .sortBy(sortFunc)
            //         .value();
            //     else vm.models = _.sortBy(initModels, sortFunc);
            //
            //     function sortFunc(item) {
            //         return _.chain(item.title).deburr().toLower().value();
            //     }
            // }

            function onDestroy() {
                currentModelSubscription.unsubscribe();
                modelsSubscription.unsubscribe();
                selectedTemplateSubscription.unsubscribe();
            }
        }

        function handleOrder() {
            vm.config['order'] = _.reduce(vm.examTemplates, function (config, item, key) {
                return _.set(config, item.id.toString(), key);
            }, {});

            startSave();
        }

        function handleConfig(template) {
            if (!_.isNil(template) && vm.config['disabled'][template.id]) vm.config['favorite'][template.id] = false;

            handleSave();
        }

        function startSave() {
            configService.set({"exam_config": vm.config}, true)
                .then(success);
        }

        function success() {
            let simpleToast = $mdToast.simple()
                .textContent($translate['instant']('exam_setup_edit_success'))
                .position("bottom left")
                .hideDelay(1500);

            $timeout(() => {
                $mdToast.show(simpleToast);
            }, 100);

        }

        function examForm(template, ev) {
            $mdDialog.show(_.assign({}, EXAM_SETUP_DIALOG, {
                targetEvent: ev,
                locals: {item: _.cloneDeep(template)}
            })).then(done);

            function done(data) {
                vm.examTemplates = _.pushOrUpdate(vm.examTemplates, data);
            }
        }

        function templateSelected(template) {
            examService.formModelState(template, null, false, true)
        }

        function editModel(model) {
            examService.formModelState(model.template, model, false, true)
        }

        function duplicateModel(model) {
            examService.formModelState(model.template, model, true, true)
        }

        function filterExamModels(template) {
            if (_.isNil(template)) template = examService.selectedTemplate.getValue();

            if (template) vm.models = _.chain(initModels)
                .filter({template_id: _.isNumber(template) ? template : template.id})
                .sortBy(sortFunc)
                .value();
            else vm.models = _.sortBy(initModels, sortFunc);

            function sortFunc(item) {
                return _.chain(item.title).deburr().toLower().value();
            }
        }

        function modelsTab(isModels) {
            vm.modelsForm = isModels;
            if (!isModels) examService.templateListState();
        }
    }

})();
